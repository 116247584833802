import React from 'react'
import styled from 'styled-components'
import { colors } from 'js-components'

import DarkPattern from './patterns/dark.svg'
import LightPattern from './patterns/light.svg'

// type Props = {
//   top?: boolean,
//   topColor?: string,
//   topAscendent?: boolean,
//   bottom?: boolean,
//   bottomColor?: string,
//   bottomAscendent?: boolean,
//   children?: Node,
//   color?: string,
//   trianglesHeight?: string,
//   backgroundSize?: string,
//   pattern?: 'dark' | 'light',
//   opacity?: number,
// }

const getPatternUrl = (pattern) => {
  switch (pattern) {
    case 'dark':
      return DarkPattern
    case 'light':
    default:
      return LightPattern
  }
}

function Angled(props) {
  const { children, pattern, backgroundSize, opacity, ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <ImageContainer
        pattern={pattern}
        backgroundSize={backgroundSize}
        opacity={opacity}
      />
      <Child trianglesHeight={restProps.top && restProps.trianglesHeight}>
        {children}
      </Child>
    </Wrapper>
  )
}

Angled.defaultProps = {
  top: false,
  topColor: colors.white,
  topAscendent: true,
  bottom: false,
  bottomColor: colors.white,
  bottomAscendent: true,
  children: null,
  color: colors.white,
  trianglesHeight: 2,
  backgroundSize: 'contain',
  pattern: 'dark',
  opacity: 1,
}

export default Angled

const Wrapper = styled.div`
  background-color: ${props => props.color};
  ${props => props.pattern && `padding-top: ${props.trianglesHeight}rem`};
  ${props => props.pattern && `padding-bottom: ${props.trianglesHeight}rem`};
  position: relative;
  z-index: 0;

  &::before,
  &::after {
    display: block;
    height: ${props => props.trianglesHeight}rem;
    position: relative;
    width: 100%;
    z-index: 2;
  }

  &::before {
    content: ${props => (props.top ? `""` : `none`)};
    background-image: ${props =>
      props.topAscendent
        ? `linear-gradient(to right bottom, rgba(255,255,255,0) 50%, ${
            props.color
          } 52%)`
        : `linear-gradient(to right top, ${
            props.color
          } 50%, rgba(255,255,255,0) 52%)`};
    top: -${props => props.trianglesHeight}rem;
  }

  &::after {
    content: ${props => (props.bottom ? `""` : `none`)};
    background-image: ${props =>
      props.bottomAscendent
        ? `linear-gradient(to right bottom, ${
            props.color
          } 50%, rgba(255,255,255,0) 52%)`
        : `linear-gradient(to right top, rgba(255,255,255,0) 50%, ${
            props.color
          } 52%)`};
    bottom: -${props => props.trianglesHeight}rem;
  }
`

const Child = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  margin-top: -${props => props.trianglesHeight}rem;
`

const ImageContainer = styled.div`
  background-attachment: scroll;
  background-size: ${props => props.backgroundSize};
  height: 100%;
  opacity: ${props => props.opacity};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  ${props =>
    props.pattern &&
    props.pattern.length &&
    `background-image: url('${getPatternUrl(props.pattern)}')`};
`
