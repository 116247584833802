import React from 'react';
import styled from 'styled-components';
import { Flex } from 'js-components';

const Section = ({ children, ...props }) => (
  <MaxWidth
    flexDirection="{['column', 'row']}"
    p={['2rem']}
    m="0 auto"
    width="100%"
    alignItems="center"
    justifyContent="center"
    maxWidth={props.maxWidth || '1080px'}
    {...props}
  >
    {children}
  </MaxWidth>
);

const MaxWidth = styled(Flex)`
  max-width: ${props => props.maxWidth};
  font-family: 'Josefin Sans';
  text-align: center;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding: 32px 16px;
`;

export default Section;
