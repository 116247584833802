// @flow
import React from 'react';
import styled from 'styled-components';

import Hero from './backgrounds/phonesInMotion.jpg';
import Sf from './backgrounds/sfSkyline.jpg';
import Bridge from './backgrounds/bridge.jpg';
import Jet from './backgrounds/jet.jpg';
import BusinessScene1 from './backgrounds/businessScene1.jpg';
import BusinessScene2 from './backgrounds/businessScene2.jpg';
import BusinessScene3 from './backgrounds/businessScene3.jpg';
import BusinessScene4 from './backgrounds/businessScene4.jpg';
import HeadsetPerson1 from './backgrounds/headsetPerson1.jpg';
import HeadsetPerson2 from './backgrounds/headsetPerson2.jpg';
import HeadsetPerson3 from './backgrounds/headsetPerson3.jpg';
import Telephony1 from './backgrounds/telephony1.jpg';
import Telephony2 from './backgrounds/telephony2.jpg';
import ServerRoom from './backgrounds/serverRoom.jpg';
import RoadLightTrails from './backgrounds/roadLightTrails.jpg';
import SuccessPractice from './backgrounds/successPractice.jpg';
import Government from './backgrounds/government.jpg';
import Racecar from './backgrounds/racecar.jpg';
import CustomersBanner from './backgrounds/customersBanner.jpg';
import ManagedByQ from './backgrounds/managedByQ.jpg';
import Sattelite from './backgrounds/sattelite.jpg';
import Chad from './backgrounds/chad.jpg';
import Wave from './backgrounds/wavePattern.jpg';
import Boardroom from './backgrounds/boardroom.jpg';
import PeopleJumping from './backgrounds/peopleJumping.jpg';
import RevenueRobots from './backgrounds/revenueRobots.jpg';

const getBackgroundUrl = background => {
  switch (background) {
    case 'hero':
      return Hero;
    case 'sf':
      return Sf;
    case 'bridge':
      return Bridge;
    case 'jet':
      return Jet;
    case 'businessScene1':
      return BusinessScene1;
    case 'businessScene2':
      return BusinessScene2;
    case 'businessScene3':
      return BusinessScene3;
    case 'businessScene4':
      return BusinessScene4;
    case 'headsetPerson1':
      return HeadsetPerson1;
    case 'headsetPerson2':
      return HeadsetPerson2;
    case 'headsetPerson3':
      return HeadsetPerson3;
    case 'telephony1':
      return Telephony1;
    case 'telephony2':
      return Telephony2;
    case 'serverRoom':
      return ServerRoom;
    case 'roadLightTrails':
      return RoadLightTrails;
    case 'successPractice':
      return SuccessPractice;
    case 'government':
      return Government;
    case 'racecar':
      return Racecar;
    case 'customersBanner':
      return CustomersBanner;
    case 'managedByQ':
      return ManagedByQ;
    case 'sattelite':
      return Sattelite;
    case 'chad':
      return Chad;
    case 'wave':
      return Wave;
    case 'boardroom':
      return Boardroom;
    case 'peopleJumping':
        return PeopleJumping;
    case 'revenueRobots':
      return RevenueRobots;
    default:
      return null;
  }
};

const getGradientType = gradientType => {
  switch (gradientType) {
    default:
    case 'dark':
      return 'linear-gradient(to bottom right, rgba(0,0,0,0.6), rgba(0,0,0,0.7))';
    case 'darkLessTransparent':
      return 'linear-gradient(to bottom right, rgba(0,0,0,0.7), rgba(0,0,0,0.9))';
    case 'darkMoreTransparent':
      return 'linear-gradient(to bottom right, rgba(0,0,0,0.2), rgba(0,0,0,0.7))';
    case 'purpleToGreen':
      return 'linear-gradient(to bottom, rgba(86,110,178,1), rgba(127,204,190,0.8))';
    case 'purple':
      return 'linear-gradient(to bottom, rgba(86,110,178,1), rgba(65,84,130,0.8))';
    case 'blueToGreen':
      return 'linear-gradient(to bottom, rgba(65,163,204,1), rgba(127,204,190,0.8))';
    case 'light':
      return 'linear-gradient(to bottom right, rgba(255,255,255,0.9), rgba(255,255,255,0.95))';
    case 'none':
      return 'linear-gradient(to bottom right, rgba(255,255,255,0.0), rgba(255,255,255,0.0))';
    case 'greenToLightGreen':
      return 'linear-gradient(355.52deg, rgba(86, 178, 161, 0.9) -2.75%, rgba(181, 214, 208, 0.9))';
    case 'trulyDark':
      return 'linear-gradient(to bottom, rgba(18,30,78,1), rgba(18,30,78,0.8))';
    case 'lightBlue':
      return 'rgba(176,239,255,0.9)';
    case 'darkBlue':
      return 'rgba(0,35,50,0.9)';
  }
};

function Background(props) {
  const { children, gradientType, background, backgroundSize, opacity } = props;
  return (
    <Wrapper>
      <BackgroundContainer background={background} backgroundSize={backgroundSize} opacity={opacity} />
      <GradientContainer gradientType={gradientType} />
      {children}
    </Wrapper>
  );
}

Background.defaultProps = {
  gradientType: 'dark',
  backgroundSize: 'fill',
  backgroundRepeat: 'none',
  background: 'hero',
  opacity: 1
};

export default Background;

const Wrapper = styled.div`
  position: relative;
  font-family: 'Josefin Sans';
  overflow: hidden;
  min-height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const GradientContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;

  ${props => `background: ${getGradientType(props.gradientType)}`};
`;

const BackgroundContainer = styled.div`
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: ${props => props.opacity};
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;

  ${props =>
    props.background && props.background.length && `background-image: url('${getBackgroundUrl(props.background)}')`};
`;
