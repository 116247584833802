import React from 'react';
import styled from 'styled-components';
import { colors } from 'js-components';

function Solid(props) {
  const { children, ...restProps } = props;

  return <Wrapper {...restProps}>{children}</Wrapper>;
}

Solid.defaultProps = {
  children: null,
  color: colors.white
};

export default Solid;

const Wrapper = styled.div`
  background-color: ${props => props.color};
  position: relative;
  z-index: 0;
  font-family: 'Josefin Sans';
  overflow: hidden;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
